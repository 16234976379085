import React, { useEffect, useRef, useState } from "react";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { ApiService } from "../../Components/Services/apiservices";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import constant from "../../Components/Services/constant";

function FAQs() {
    const { slug } = useParams();
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [pageContent, setPageContent] = useState("");
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        if (didMountRef.current) {
            getFaqData()
            const getPageData = {
                slug: "faqs",
            };
            ApiService.postData("page-content", getPageData).then((res) => {
                if (res.status === "success") {
                    setPageData(res.data);
                    setPageContent(res.data.page_content);
                }
            });
        }
        didMountRef.current = false;
    });

    const getFaqData = () => {
        ApiService.fetchData("faqdata").then((res) => {
            if (res.status === "success") {
                setFaqData(res.faqData)

            }
        })

    }

    return (
        <>
            <Helmet>
                <title>{pageData.page_meta_title}</title>
                <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : ""} />
                {pageData.page_meta_keyword != null ? <meta name="keywords" content={pageData.page_meta_keyword} /> : ""}
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={pageData.page_meta_title} />
                <meta name="twitter:url" content={window.location.href} />
                <meta property="og:image" content={constant.FRONT_URL + 'img/logo.png'} />
                <meta property="og:url" content={window.location.href} />
                {pageData.page_meta_desc != null ? <meta property="og:description" content={pageData.page_meta_desc} /> : ""}
                <meta name="twitter:title" content={pageData.page_meta_title} />
                {pageData.page_meta_desc != null ? <meta name="twitter:description" content={pageData.page_meta_desc} /> : ""}
                <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
            </Helmet>
            <Header state="inner-header" />
            <div className="subheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1>{pageData.page_name}</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {pageData.page_name}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </div>

            <section className="sec-gap-medium">
                <Container>
                    <Row>
                        <div className="text-center mb-4">
                            <h2>FAQ</h2>
                        </div>

                        <Col lg={12}>
                            <Accordion>
                                {faqData.map((value, index) => {
                                    return (
                                        <Accordion.Item key={index} eventKey={index}>
                                            <Accordion.Header>
                                                <h6 className="mb-0 tx-14">{value.faq_title}</h6>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div >
                                                    <p dangerouslySetInnerHTML={{ __html: value.faq_description }}></p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className="sec-gap-medium">
            <Container>
                    <Row>
                        <Col lg={12}>
                        <div className="text-center">
                        <h2>Our products are designed and handmade in `Perth, Western Australia`.</h2>
                        </div>
                        </Col>
                        </Row>
                        </Container>
            </section>
            <Footer />

        </>
    )
}

export default FAQs
