import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../../Components/Footer";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { ApiService } from "../../Components/Services/apiservices";
import CartHeader from "../../Components/CartHeader";
import sessionCartData from "../../Components/Elements/cart_session_data";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { showToast } from "../../Components/Elements/toastUtils";
import DataContext from "../../Components/Elements/eventContext";
import { addToCart, minusToCart, removeToCart } from "../../Components/Elements/add_to_cart";
import { toast } from "react-toastify";
import constant from "../../Components/Services/constant";
import Loader from "react-js-loader";
function Cart() {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext)
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [setSession, SetSession] = useState("");
  const dataArray = sessionCartData(); 
  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCouponSession(dataArray[2])
      if (localStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      } else {
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setCartSummary(dataArray[3])
      }
      SetSession(localStorage.getItem("USER_TOKEN")); 
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 500);
    }
    didMountRef.current = false;
  }, []);

  const cartSessionData = () => {
    const dataString = {
      coupon_session: localStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }

  const setSessionSummary = () => {
    let parsedCartSession = localStorage.getItem("CART_SESSION");
    parsedCartSession = parsedCartSession ? JSON.parse(parsedCartSession) : [];
    const parsedCartSummary = {
      itemTotal: 0,
      discount: 0,
      total_amount: 0,
      shipping_charge: 0,
      sellingTotal: 0,
    };

    parsedCartSummary.itemTotal = parsedCartSession.reduce((total, cartItem) => {
      const price = parseFloat(cartItem.product_price) || 0;
      const quantity = Number(cartItem.quantity) || 0;
      return total + price * quantity;
    }, 0);

    parsedCartSummary.discount = parsedCartSession.reduce((total, cartItem) => {
      const price = parseFloat(cartItem.product_price) || 0;
      const sellingPrice = parseFloat(cartItem.product_selling_price) || 0;
      const quantity = Number(cartItem.quantity) || 0;
      return total + (price - sellingPrice) * quantity;
    }, 0);

    parsedCartSummary.sellingTotal = parsedCartSession.reduce((total, cartItem) => {
      const sellingPrice = parseFloat(cartItem.product_selling_price) || 0;
      const quantity = Number(cartItem.quantity) || 0;
      return total + sellingPrice * quantity;
    }, 0);

    parsedCartSummary.total_amount = parseFloat(parsedCartSummary.itemTotal) - parseFloat(parsedCartSummary.discount);
    contextValues.setCartSummary(parsedCartSummary)
  }

  const plusToCart = async (productValue, index) => {
    contextValues.setSpinnerCubLoader(index)
    if (localStorage.getItem("USER_TOKEN")) {
      ApiService.postData("plus-to-cart", productValue).then((res) => {
        if (res.status === "success") {
          localStorage.removeItem("COUPON_SESSION");
          cartSessionData()
          toast.success('Cart Updated Successfully');
          setTimeout(() => {
            contextValues.setSpinnerCubLoader(0)
          }, 500);
        } else {
          toast.error(res.message);
          setTimeout(() => {
            contextValues.setSpinnerCubLoader(0)
          }, 500);
        }
      });
    } else {
      const productData = {
        product_id: Number(productValue.product_id),
        product_name: productValue.product_name,
        product_slug: productValue.product_slug,
        product_image: productValue.product_image ? productValue.product_image : constant.DEFAULT_IMAGE,
        product_type: Number(productValue.product_type),
        product_price: parseFloat(productValue.product_price),
        product_selling_price: parseFloat(productValue.product_selling_price),
        product_discount: parseFloat(productValue.product_discount),
        product_variation: productValue.product_variation,
        product_category_id: productValue.product_category_id,
      };
      const updateStatus = await addToCart(productData, 1, contextValues);
      if (updateStatus) {
        setSessionSummary()
        setTimeout(() => {
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      } else {
        setTimeout(() => {
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      }
    }
  };

  const minustocart = (productValue, index) => {
    contextValues.setSpinnerCubLoader(index)
    if (localStorage.getItem("USER_TOKEN")) {
      ApiService.postData("minus-to-cart", productValue).then((res) => {
        if (res.status === "success") {
          localStorage.removeItem("COUPON_SESSION");
          cartSessionData()
          toast.success('Cart Updated Successfully');
          setTimeout(() => {
            contextValues.setSpinnerCubLoader(0)
          }, 500);
        } else {
          toast.error(res.message);
          setTimeout(() => {
            contextValues.setSpinnerCubLoader(0)
          }, 500);
        }
      });
    } else {
      if (minusToCart(productValue, productValue.product_variation, contextValues)) {
        setTimeout(() => {
          setSessionSummary()
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      } else {
        setTimeout(() => {
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      }
    }
  };

  const deleteToCart = async (productValue) => {
    setSpinnerLoading(true)
    if (localStorage.getItem("USER_TOKEN")) {
      const dataString = {
        cart_id: productValue.cart_id,
      };
      ApiService.postData("removecartproduct", dataString).then((res) => {
        if (res.data.status === "success") {
          localStorage.removeItem("COUPON_SESSION");
          cartSessionData()
          setTimeout(() => {
            setSpinnerLoading(false);
          }, 500);
        } else {
          setTimeout(() => {
            setSpinnerLoading(false);
          }, 500);
        }
      });
    } else {
      const updateStatus = await removeToCart(productValue, productValue.product_variation, contextValues);
      if (updateStatus) {
        setTimeout(() => {
          let cartSessionCount = localStorage.getItem("CART_SESSION");
          cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
          contextValues.setCartSessionData(cartSessionCount)
          contextValues.setCartCount(cartSessionCount.length)
          setSessionSummary()
          setSpinnerLoading(false)
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false)
        }, 500);
      }
    }
  };

  const addtofavcart = (productId) => {
    const dataString = {
      product_id: productId.product_id
    }
    setSpinnerLoading(true)
    ApiService.postData("add-to-fav-cart", dataString).then((res) => {
      if (res.data.status === "success") {
        const dataString = {
          cart_id: productId.cart_id,
        };
        ApiService.postData("removecartproduct", dataString).then((res) => {
          if (res.data.status === "success") {
            localStorage.removeItem("COUPON_SESSION");
            cartSessionData()
            setTimeout(() => {
              showToast('success', 'Moved to wishlist', 1000);
              setSpinnerLoading(false)
            }, 500);
          } else {
            setTimeout(() => {
              showToast('success', 'Moved to wishlist', 1000);
              setSpinnerLoading(false)
            }, 500);
          }
        });
      } else {
        setSpinnerLoading(false)
      }
    });
  } 

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  return (
    <>
      <CartHeader />
      {spinnerLoading ? <SpinnerLoader /> : 
      <>
      <BrowserView>
        <section className="sec-gap-small">
          <div className="container">
            <div className="row">
              {contextValues.cartSessionData.length > 0 ? (
                <>
                  <div className="col-lg-8">
                    {contextValues.cartSessionData.map((value, index) => {
                      return (
                        <div className="cartsec" key={index}>
                          <div className="row g-3">
                            <div className="col-lg-3 col-3">
                              <div className="cartsec-media">
                                <img src={value.product_image} />
                              </div>
                            </div>
                            <div className="col-lg-9 col-9">
                              <h6 className="cartsec-name">
                                <a href={"/product/" + value.product_slug}>{value.product_name}</a>
                              </h6>
                              <div className="cartsec-price">
                                <div className="price-new me-2">{multiCurrency(value.product_selling_price)}</div>
                                {value.product_discount > 0 ? <div className="price-old">{multiCurrency(value.product_price)}</div> : null}
                              </div>
                              <div className="cartsec-footer">
                                <div className="qty-changer">
                                  <button onClick={(e) => minustocart(value, index + Number(1))}><i className="fas fa-minus psbmiuns"></i></button>
                                  <span id="spanQty20">{contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#212529'} color={'#212529'} size={20} /> : <> {value.quantity}</>}</span>
                                  <button onClick={(e) => plusToCart(value, index + Number(1))}><i className="fas fa-plus psbplus"></i></button>
                                </div>
                                <div className="cartsec-buttongroup">
                                  {setSession ?
                                    <a href="javascript:void(0)" onClick={(e) => addtofavcart(value)}>
                                      <i className="fas fa-heart"></i>
                                    </a>
                                    : <a href="javascript:void(0)" onClick={(e) =>loginModal()}>
                                      <i className="fas fa-heart"></i>
                                    </a>}
                                  <a href="javascript:void(0)" onClick={(e) => deleteToCart(value)}>
                                    <i className="fas fa-trash"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-lg-4">
                    <div className="panel p-4 mb-3">
                      <div className="panel-header">Cart Summary</div>
                      <div className="panel-body">
                        <div className="pcb-list mt-3">
                          <ul>
                            <li>Sub Total<span className="ml-auto">{multiCurrency(contextValues.cartSummary.itemTotal)}</span></li>
                            {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (<li>Discount<span className="ml-auto tx-green">-{multiCurrency(contextValues.cartSummary.discount)}</span></li>)}
                            <li> Shipping & taxes  <span className="ml-auto">calculated at checkout</span></li>
                          </ul>
                        </div>
                        <hr />
                        <div className="pcb-list-second">
                          <ul>
                            <li>Total Amount<span className="ml-auto">{multiCurrency(contextValues.cartSummary.total_amount)}</span></li>
                          </ul>
                        </div>
                        <hr />
                        <p className="text-center mt-20">We Accepted all Major Cards</p>
                        <div className="cardlist">
                          <i className="fab fa-cc-paypal"></i>
                          <i className="fab fa-cc-mastercard"></i>
                          <i className="fab fa-cc-discover"></i>
                          <i className="fab fa-cc-visa"></i>
                        </div>
                      </div>
                    </div>
                    <a href="/address" className="btn btn-primary btn-block btn-large" > Proceed to Checkout </a>
                  </div>
                </>
              ) : (
                <div className="noimg">
                  <img src="/img/empty-cart.webp" className="img-fluid mb-3" />
                  <h6>Your cart is empty!</h6>
                  <p>There is nothing in your cart. Let's add some items</p>
                  <a href="/" className="btn btn-primary-outline btn-large">Continue Shopping</a>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <div>
          {contextValues.cartSessionData.length > 0 ? (
            <>
              <div>
                {contextValues.cartSessionData.map((value, index) => {
                  return (
                    <div className="cartsec" key={index}>
                      <div className="row g-3">
                        <div className="col-lg-3 col-3">
                          <div className="cartsec-media">
                            <img src={value.product_image} />
                          </div>
                        </div>
                        <div className="col-lg-9 col-9">
                          <h6 className="cartsec-name">
                            <a href={"/product/" + value.product_slug}>{value.product_name}</a>
                          </h6>
                          <div className="cartsec-price">
                            <div className="price-new me-2">
                              {multiCurrency(value.product_selling_price)}
                            </div>
                            {value.product_discount > 0 ?
                              <div className="price-old">
                                {multiCurrency(value.product_price)}
                              </div>
                              : null}
                          </div>
                          <div className="cartsec-footer">
                            <div className="qty-changer">
                                <button onClick={(e) => minustocart(value, index + Number(1))}><i className="fas fa-minus psbmiuns"></i></button>
                                <span id="spanQty20">{contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#212529'} color={'#212529'} size={20} /> : <> {value.quantity}</>}</span>
                                <button onClick={(e) => plusToCart(value, index + Number(1))}><i className="fas fa-plus psbplus"></i></button>
                              </div>
                            <div className="cartsec-buttongroup">
                              <a href="javascript:void(0)" onClick={(e) => addtofavcart(value)}>
                                <i className="fas fa-heart"></i>
                               
                              </a>
                              <a href="javascript:void(0)" onClick={(e) => deleteToCart(value)}>
                                <i className="fas fa-trash"></i>
                                
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              <div className="panel checkoutlist">
                <div className="panel-header">Cart Summary</div>
                <div className="panel-body">
                  <div className="pcb-list mt-2">
                    <ul>
                      <li>Sub Total<span className="ml-auto">{multiCurrency(contextValues.cartSummary.itemTotal)}</span></li>
                      {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (<li>Discount<span className="ml-auto tx-green">-{multiCurrency(contextValues.cartSummary.discount)}</span></li>)}
                      <li> Shipping & taxes  <span className="ml-auto">calculated at checkout</span></li>
                    </ul>
                  </div>
                  <hr />
                  <div className="pcb-list-second">
                    <ul>
                      <li>Total Amount<span className="ml-auto">{multiCurrency(contextValues.cartSummary.total_amount)}</span></li>
                    </ul>
                  </div>
                  <hr />
                  <p className="text-center mt-20">We Accepted all Major Cards</p>
                  <div className="cardlist">
                    <i className="fab fa-cc-paypal"></i>
                    <i className="fab fa-cc-mastercard"></i>
                    <i className="fab fa-cc-discover"></i>
                    <i className="fab fa-cc-visa"></i>
                  </div>
                </div>
              </div>
              <div className="footer-checkout">
                <a href="/address" className="btn btn-primary btn-block btn-large">Proceed to Checkout</a>
              </div>
            </>
          ) : (
            <div className="noimg">
              <img src="/img/empty-cart.webp" className="img-fluid mb-3" />
              <h6>Your cart is empty!</h6>
              <p>There is nothing in your cart. Let's add some items</p>
              <a href="/" className="btn btn-primary-outline btn-medium">Continue Shopping</a>
            </div>
          )}
        </div>
      </MobileView> 
      </>
      }
      
    </>
  );
}
export default Cart;
