import React, { useContext, useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BrowserView, MobileView } from 'react-device-detect';
import { validEmail } from '../../Components/Elements/Regex';
import Alert from 'react-bootstrap/Alert';
import { useLocation } from "react-router-dom";
import LoginModal from "../Modals/login_modal";
import ScrollToTop from "react-scroll-to-top";
import DataContext from "../Elements/eventContext";
function Footer() {
  const contextValues = useContext(DataContext)
  const location = useLocation();
  const didMountRef = useRef(true);
  const [show, setShow] = useState(false);
  const [settingData, setSettingData] = useState([]);
  const [settingImagePath, setSettingImagePath] = useState("");
  const [footer1, setFooter1] = useState("");
  const [footer2, setFooter2] = useState("");
  const [footer3, setFooter3] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [setSession, SetSession] = useState('')
  const [accountStatus, SetAccountStatus] = useState(false)
  const [whatsappUrl, setWhatsappUrl] = useState('')
  const [newsletterDetails, setNewsletterDetails] = useState({
    newsletter_email: "",
  });
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {
    setShow(status)
  };
  useEffect(() => {
    if (didMountRef.current) {
      getSettingsData();
      getFooterData();
      SetSession(localStorage.getItem('USER_TOKEN'))
      const currentPath = location.pathname;
      const isAccountOverview = currentPath.includes("/account/account-overview");
      const isAddressPage = currentPath.includes("/account/address");
      const isWishlistPage = currentPath.includes("/account/wishlist");
      const ishelpandsupportPage = currentPath.includes("/account/help-and-support");
      const isaboutusPage = currentPath.includes("/account/about-us");

      if (isAccountOverview || isWishlistPage || isAddressPage || ishelpandsupportPage || isaboutusPage) {
        SetAccountStatus(true)
      } else {
        SetAccountStatus(false)
      }
    }
    didMountRef.current = false;
  }, []);
  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status == "success") {
        setSettingData(res.sitesettings);
        setSettingImagePath(res.setting_image_path)
        setWhatsappUrl(res.sitesettings.admin_whatsapp_no ? "https://wa.me/" + res.sitesettings.admin_whatsapp_no : "")
      }
    });
  };
  const getFooterData = () => {
    ApiService.fetchData("footer").then((res) => {
      if (res.status == "success") {
        setFooter1(res.footerData.footer_desc1);
        setFooter2(res.footerData.footer_desc2);
        setFooter3(res.footerData.footer_desc3);
      }
    });
  };
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setNewsletterDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }

  const resetNewsletterForm = () => {
    setNewsletterDetails({ newsletter_email: "" });
  };
  const newsletterProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("newsletterRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (!validEmail.test(newsletterDetails.newsletter_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      }
      ApiService.postData('newsletter-process', newsletterDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message)
          resetNewsletterForm();
          setTimeout(() => {
            setSuccessMessage('')
          }, 2000);
        } else {
          setErrorMessage(res.message)
          setTimeout(() => {
            setErrorMessage('')
          }, 2000);
        }
      });
    }
  };
  return (
    <>

      <BrowserView>

        <footer>
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-logo mb-3">

                  <a href="/">
                    <img
                      src={settingData.footer_logo != null ? settingImagePath + settingData.footer_logo : "/img/logo.png"} alt={settingData.footer_logo} width={200} height={75} />

                  </a>

                </div>
                <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                {/* <div className="footer-contact">
                  <ul>
                    <li>
                      <a href={"tel:" + settingData.admin_mobile}>
                        <i className="d-icon-phone mr-5"></i>
                        <span>{settingData.admin_mobile}</span>
                      </a>
                    </li>
                    <li>
                      <a href={"mailto:" + settingData.admin_email}>
                        <i className="fa fa-envelope  mr-5"></i>
                        <span>{settingData.admin_email}</span>
                      </a>
                    </li>
                  </ul>


                </div> */}

              </Col>
              <Col lg={2}>
                <div dangerouslySetInnerHTML={{ __html: footer2 }}></div>

              </Col>
              <Col lg={2}>
                <div dangerouslySetInnerHTML={{ __html: footer3 }}></div>
              </Col>
              <Col lg={4}>
                <div className="footer-title">
                  <h5>Subscribe to Our News Letter</h5>
                </div>
                <p>Enter your email address to register to our newsletter subscription</p>
                {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                <div className="subscribe-form mb-4">
                  <input
                    className="email newsletterRequired"
                    type="email"
                    placeholder="Enter your email here.."
                    name="newsletter_email"
                    value={newsletterDetails.newsletter_email}
                    onChange={(e) => onTodoChange(e)}
                  />
                  <div className="subscribe-button">
                    <input
                      id="mc-embedded-subscribe"
                      className="button"
                      type="button"
                      name="subscribe"
                      value="Subscribe"
                      onClick={newsletterProcess}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <div className="footer-bottom">
          <div className="footer-left mb-4">
            <figure className="payment">
              <img src="/img/paymentsfooter.jpg" alt="payment" />
            </figure>
          </div>

          <div className="footer-center mb-4">
            <p className="copyright mb-0">St. James Supply Co. © 2024. All Rights Reserved</p>
          </div>

          <div className="footer-right">
            <div className="footer-social">
              <ul>
                {settingData.facebook_url != null ? (
                  <li>
                    <a href={settingData.facebook_url} target="new">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.twitter_url != null ? (
                  <li>
                    <a href={settingData.twitter_url} target="new">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.pinterest_url != null ? (
                  <li>
                    <a href={settingData.pinterest_url} target="new">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.instagram_url != null ? (
                  <li>
                    <a href={settingData.instagram_url} target="new">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}


              </ul>

            </div>
          </div>

        </div>
        {/* <div className="whatsapp"><a href={whatsappUrl} target="new"><img src="/img/whatsapp1.png" ></img></a></div> */}
        <ScrollToTop smooth style={{ bottom: "30px", right: "23px" }} > <i className="fas fa-chevron-up"></i> </ScrollToTop>
      </BrowserView>

      <MobileView>
        <div className="footer-bottom">
          <div className="footer-left mb-4">
            <figure className="payment">
              <img src="img/paymentsfooter.jpg" alt="payment" />
            </figure>
          </div>

          <div className="footer-center mb-4">
            <p className="copyright mb-0">St. James Supply Co. © 2024. All Rights Reserved</p>
          </div>

          <div className="footer-right">
            <div className="footer-social">
              <ul>
                {settingData.facebook_url != null ? (
                  <li>
                    <a href={settingData.facebook_url} target="new">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.twitter_url != null ? (
                  <li>
                    <a href={settingData.twitter_url} target="new">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.pinterest_url != null ? (
                  <li>
                    <a href={settingData.pinterest_url} target="new">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {settingData.instagram_url != null ? (
                  <li>
                    <a href={settingData.instagram_url} target="new">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}


              </ul>

            </div>
          </div>
        </div>


        <div className="appBottomMenu">

          <a href="/" className={location.pathname === "/" ? "item active" : "item"}><div className="col"><i className="d-icon-home"></i><span>Home</span></div>
          </a>
          <a href="/category" className={location.pathname.includes("/category") ? "item active" : "item"}>

            <div className="col">
              <i className="d-icon-layer"></i>
              <span>Categories</span>
            </div>
          </a>
          {setSession ?
            <a href="/account/orders" className={location.pathname.includes("/account/orders") ? "item active" : "item"}>

              <div className="col">
                <i className="d-icon-truck"></i>
                <span>Orders</span>
              </div>
            </a> :
            <a href="javascript:void(0)" className="item" onClick={loginModal}>
              <div className="col">
                <i className="d-icon-truck"></i>
                <span>Orders</span>
              </div>
            </a>}
          <a href="/cart" className={location.pathname.includes("/cart") ? "item active" : "item"}>
            <div className="col">
              <i className="d-icon-bag"></i>
              <span>Cart</span>
            </div>
          </a>
          {setSession ?
            <a href="/account/account-overview" className={accountStatus ? "item active" : "item"}>
              <div className="col">
                <i className="d-icon-user"></i>
                <span>Account</span>
              </div>
            </a> : <a href="javascript:void(0)" className="item" onClick={loginModal}>
              <div className="col">
                <i className="d-icon-user"></i>
                <span>Account</span>
              </div>
            </a>
          }

        </div>

        {/* <div className="whatsapp-mobile"><a href={whatsappUrl} target="new"><img src="/img/whatsapp1.png"></img></a></div> */}
        <ScrollToTop smooth style={{ bottom: "60px", right: "20px" }} > <i className="fas fa-chevron-up"></i> </ScrollToTop>
      </MobileView>

    </>
  )
}
export default Footer